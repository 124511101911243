import { css } from 'styled-components';
import { above } from '../styles/breakpoints';

export const LayoutPadding = `
    padding: 1.5em;
    ${above.small`
        padding: 1.5em;
    `}
    ${above.med`
        padding: 3em;
        padding-bottom: 1.5em;
    `}
    ${above.large`
        padding: 3em;
        padding-bottom: 1.5em;
    `}
`;

export const HeaderPadding = `
    padding: 1em;
    ${above.small`
        padding: 1.5em;
    `}
    ${above.med`
        padding: 1.5em;
    `}
    ${above.large`
        padding: 1.5em;
    `}
    ${above.fullHD`
      font-size: 2em;
    `}
`;
