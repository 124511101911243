import styled from 'styled-components';
import { animated } from 'react-spring';

export const CleanButton = styled.button`
    background: none;
	color: inherit;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
	outline: inherit;
`
export const ACleanButton = animated(CleanButton);