import throttle from 'lodash/throttle';
import { useEffect, useState } from "react";

const scrollY = () => typeof window !== `undefined` ? window.scrollY : 0;

export function useScrollPosition(throttleTime = 100) {


    const [y, setValueY] = useState(scrollY());


    useEffect(() => {
        const handler = () => {
            setValueY(scrollY())
        }

        const options = { trailing: true };

        const onScroll = throttle(
            handler,
            throttleTime,
            options,
        );

        onScroll();
        window.addEventListener('scroll', onScroll);
        return () => window.removeEventListener('scroll', onScroll);
    }, [throttleTime])


    return y;

}
