// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-en-404-js": () => import("./../../../src/pages/en/404.js" /* webpackChunkName: "component---src-pages-en-404-js" */),
  "component---src-pages-en-about-js": () => import("./../../../src/pages/en/about.js" /* webpackChunkName: "component---src-pages-en-about-js" */),
  "component---src-pages-en-cases-js": () => import("./../../../src/pages/en/cases.js" /* webpackChunkName: "component---src-pages-en-cases-js" */),
  "component---src-pages-en-contact-js": () => import("./../../../src/pages/en/contact.js" /* webpackChunkName: "component---src-pages-en-contact-js" */),
  "component---src-pages-en-employees-js": () => import("./../../../src/pages/en/employees.js" /* webpackChunkName: "component---src-pages-en-employees-js" */),
  "component---src-pages-en-index-js": () => import("./../../../src/pages/en/index.js" /* webpackChunkName: "component---src-pages-en-index-js" */),
  "component---src-pages-en-jobs-js": () => import("./../../../src/pages/en/jobs.js" /* webpackChunkName: "component---src-pages-en-jobs-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-nb-404-js": () => import("./../../../src/pages/nb/404.js" /* webpackChunkName: "component---src-pages-nb-404-js" */),
  "component---src-pages-nb-about-js": () => import("./../../../src/pages/nb/about.js" /* webpackChunkName: "component---src-pages-nb-about-js" */),
  "component---src-pages-nb-cases-js": () => import("./../../../src/pages/nb/cases.js" /* webpackChunkName: "component---src-pages-nb-cases-js" */),
  "component---src-pages-nb-contact-js": () => import("./../../../src/pages/nb/contact.js" /* webpackChunkName: "component---src-pages-nb-contact-js" */),
  "component---src-pages-nb-employees-js": () => import("./../../../src/pages/nb/employees.js" /* webpackChunkName: "component---src-pages-nb-employees-js" */),
  "component---src-pages-nb-index-js": () => import("./../../../src/pages/nb/index.js" /* webpackChunkName: "component---src-pages-nb-index-js" */),
  "component---src-pages-nb-jobs-js": () => import("./../../../src/pages/nb/jobs.js" /* webpackChunkName: "component---src-pages-nb-jobs-js" */),
  "component---src-pages-newsletter-js": () => import("./../../../src/pages/newsletter.js" /* webpackChunkName: "component---src-pages-newsletter-js" */),
  "component---src-templates-case-jsx": () => import("./../../../src/templates/Case.jsx" /* webpackChunkName: "component---src-templates-case-jsx" */),
  "component---src-templates-custom-page-jsx": () => import("./../../../src/templates/CustomPage.jsx" /* webpackChunkName: "component---src-templates-custom-page-jsx" */),
  "component---src-templates-job-jsx": () => import("./../../../src/templates/Job.jsx" /* webpackChunkName: "component---src-templates-job-jsx" */),
  "component---src-templates-newsletter-jsx": () => import("./../../../src/templates/Newsletter.jsx" /* webpackChunkName: "component---src-templates-newsletter-jsx" */)
}

