import { useEffect, useRef, useState } from "react";
const angleBetween = (p1, p2) => Math.atan2(p2.y - p1.y, p2.x - p1.x) * 180 / Math.PI;
const distanceBetween = (p1, p2) => Math.sqrt(
    Math.pow(p1.y - p2.y, 2) +
    Math.pow(p1.x - p2.x, 2)
)


export function useGlobalMouseOverAdvanced(ref, advancedHandler) {

    // const [isMouseOver, setIsMouseOver] = useState(false);
    const extra = useRef({
        isMouseOver: false,
        x: 0,
        y: 0,
        height: 0,
        width: 0,
        centerX: 0,
        centerY: 0,
        distanceFromCenter: 0,
        angleFromCenter: 0
    });

    const advancedHandlerRef = useRef(advancedHandler);

    useEffect(() => {
        advancedHandlerRef.current = advancedHandler;
    }, [advancedHandler]);

    useEffect(() => {

        const handler = (event) => {
            if (ref.current) {

                const { top, left, height, width } = ref.current.getBoundingClientRect();
                const { clientX: x = 0, clientY: y = 0 } = event || {};

                if (left <= x && x <= (left + width)) {
                    if (top <= y && y <= (top + height)) {
                    // setIsMouseOver(true);
                        const xx = x - left;
                        const yy = y - top;
                        const centerX = ~~(width / 2);
                        const centerY = ~~(height / 2);
                        const xy = { x: xx, y: yy };
                        const center = { x: centerX, y: centerY }
                        const distanceFromCenter = distanceBetween(xy, center);
                        const angleFromCenter = angleBetween(xy, center);

                        extra.current = {
                            isMouseOver: true,
                            height,
                            width,
                            ...xy,
                            centerX,
                            centerY,
                            distanceFromCenter,
                            angleFromCenter
                        };

                        if (advancedHandlerRef.current) {
                            advancedHandlerRef.current(extra.current);
                        }


                    } else {
                        if (advancedHandlerRef.current) {
                            extra.current.isMouseOver = false;
                            advancedHandlerRef.current(extra.current);
                        }
                    }
                } else {
                    if (advancedHandlerRef.current) {
                        extra.current.isMouseOver = false;
                        advancedHandlerRef.current(extra.current);
                    }
                }
            } else {
                if (advancedHandlerRef.current) {
                    extra.current.isMouseOver = false;
                    advancedHandlerRef.current(extra.current);
                }
            }
        };
        document.addEventListener('mousemove', handler);

        return () => document.removeEventListener('mousemove', handler);

    }, [ref])

}