import { css } from 'styled-components';

export const Sizes = {
  xs: 300,
  small: 400,
  med: 760,
  large: 1000,
  hdReady: 1280,
  hdMiddle: 1400,
  fullHD: 1920,
};

export const minWidth = (px) => (first, ...interpolations) => css`
  @media (min-width: ${px / 16}em) {
    ${css(first, ...interpolations)}
  }
`;

export const maxWidth = (px) => (first, ...interpolations) => css`
  @media (max-width: ${px / 16}em) {
    ${css(first, ...interpolations)}
  }
`;
export const minHeight = (px) => (first, ...interpolations) => css`
  @media (min-height: ${px / 16}em) {
    ${css(first, ...interpolations)}
  }
`;

export const maxHeight = (px) => (first, ...interpolations) => css`
  @media (max-height: ${px / 16}em) {
    ${css(first, ...interpolations)}
  }
`;

export const above = {
  /** 300px */
  xs: minWidth(Sizes.xs),
  /** 400px */
  small: minWidth(Sizes.small),
  /** 760px */
  med: minWidth(Sizes.med),
  /** 1000px */
  large: minWidth(Sizes.large),
  /** 1280px */
  hdReady: minWidth(Sizes.hdReady),
  /** 1400px */
  hdMiddle: minWidth(Sizes.hdMiddle),
  /** 1920px */
  fullHD: minWidth(Sizes.fullHD),
};
export const below = {
  /** 300px */
  xSmall: maxWidth(Sizes.xs),
  /** 400px */
  small: maxWidth(Sizes.small),
  /** 760px */
  med: maxWidth(Sizes.med),
  /** 1000px */
  large: maxWidth(Sizes.large),
  /** 1280px */
  hdReady: maxWidth(Sizes.hdReady),
  /** 1400px */
  hdMiddle: maxWidth(Sizes.hdMiddle),
  /** 1920px */
  fullHD: maxWidth(Sizes.fullHD),
};
