import { normalize } from 'polished';
import { above, minWidth } from './breakpoints';
import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  ${normalize()};

  @font-face {
    font-family: 'Euclid Square';
    src: url('/euclid-square/EuclidSquare-Semibold.woff2') format("woff2"),url('/euclid-square/EuclidSquare-Semibold.woff') format("woff");
    font-weight: 600;
    font-style: normal
}

@font-face {
    font-family: 'Euclid Square';
    src: url('/euclid-square/EuclidSquare-Light.woff2') format("woff2"),url('/euclid-square/EuclidSquare-Light.woff') format("woff");
    font-weight: 300;
    font-style: normal
}

@font-face {
    font-family: 'Euclid Square';
    src: url('/euclid-square/EuclidSquare-BoldItalic.woff2') format("woff2"),url('/euclid-square/EuclidSquare-BoldItalic.woff') format("woff");
    font-weight: 700;
    font-style: italic
}

@font-face {
    font-family: 'Euclid Square';
    src: url('/euclid-square/EuclidSquare-MediumItalic.woff2') format("woff2"),url('/euclid-square/EuclidSquare-MediumItalic.woff') format("woff");
    font-weight: 500;
    font-style: italic
}

@font-face {
    font-family: 'Euclid Square';
    src: url('/euclid-square/EuclidSquare-LightItalic.woff2') format("woff2"),url('/euclid-square/EuclidSquare-LightItalic.woff') format("woff");
    font-weight: 300;
    font-style: italic
}

@font-face {
    font-family: 'Euclid Square';
    src: url('/euclid-square/EuclidSquare-SemiboldItalic.woff2') format("woff2"),url('/euclid-square/EuclidSquare-SemiboldItalic.woff') format("woff");
    font-weight: 600;
    font-style: italic
}

@font-face {
    font-family: 'Euclid Square';
    src: url('/euclid-square/EuclidSquare-Regular.woff2') format("woff2"),url('/euclid-square/EuclidSquare-Regular.woff') format("woff");
    font-weight: 400;
    font-style: normal
}

@font-face {
    font-family: 'Euclid Square';
    src: url('/euclid-square/EuclidSquare-Bold.woff2') format("woff2"),url('/euclid-square/EuclidSquare-Bold.woff') format("woff");
    font-weight: 700;
    font-style: normal
}

@font-face {
    font-family: 'Euclid Square';
    src: url('/euclid-square/EuclidSquare-Medium.woff2') format("woff2"),url('/euclid-square/EuclidSquare-Medium.woff') format("woff");
    font-weight: 500;
    font-style: normal
}

@font-face {
    font-family: 'Euclid Square';
    src: url('/euclid-square/EuclidSquare-RegularItalic.woff2') format("woff2"),url('/euclid-square/EuclidSquare-RegularItalic.woff') format("woff");
    font-weight: 400;
    font-style: italic
}

  html {
    box-sizing: border-box;
    min-height: 100vh;
  }


  *, *:before, *:after {
    box-sizing: inherit;
  }

  .main {
    width: 100%;
  }
  

  body {
    margin: 0;
    padding: 0;
    font-family: 'Euclid Square', -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
      "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: var(--theme-secondary, #f2f2f2);
    color: var(--theme-primary, #ae8150);
    overflow-x: hidden;
    font-size: 0.8rem;
    min-height: 100vh;
    overflow-y: scroll;
    transform-style: flat;
  
    transition: background-color .5s ease,color .5s ease, fill .5s ease, stroke .5s ease2;
    opacity: 1 !important;
    ${above.small`
      font-size: 1rem;
    `}
    /* ${above.med`
    font-size: 1rem;
    `}
    ${above.large`
      font-size: 1rem;
    `} */
    ${above.hdReady`
      font-size: 1.3rem;
    `}
    ${above.fullHD`
      font-size: 1.5rem;
    `}
    ${minWidth(2560)`
      font-size: 1.5vw;
    `}
  }
  .mt {
    margin-top: 5em;
  }
  .mb {
    margin-bottom: 5em;
  }
  .mb-2 {
    margin-bottom: 10em;
  }
  .pt {
    padding-top: 5em;
  }
  .pb {
    padding-bottom: 5em;
  }

  a {
    color: var(--theme-primary, #ae8150);
    :hover {
      color: var(--theme-link, #725b43);
    }
    text-decoration: none;
  }

  svg {
    fill: var(--theme-primary, #ae8150);
    stroke: var(--theme-primary, #ae8150);
  }

  svg.invert {
    fill: var(--theme-secondary, #f2f2f2);
    stroke: var(--theme-secondary, #f2f2f2);
  }

  ::selection {
    background: var(--theme-primary, #ae8150);
    color: var(--theme-secondary, #f2f2f2);
  }
  h1, h2, h3, h4, h5, h6 {
    font-size: 1em;
  }
  gyro-animation {
    height: 100vh;
  }
  #menu {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    pointer-events: none;
    z-index: 1000;
  }
  #area {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    pointer-events: none;
  }
  .layout-padding {
    padding: 1.5em;
    ${above.small`
        padding: 1.5em;
    `}
    ${above.med`
        padding: 3em;
        padding-bottom: 1.5em;
    `}
    ${above.large`
        padding: 3em;
        padding-bottom: 1.5em;
    `}
  }
`;
