import ReactDOM from 'react-dom';

const MenuPortal = ({ containerRef, children }) =>
  containerRef ? ReactDOM.createPortal(children, containerRef) : null;

export default MenuPortal;

// export class MenuPortal extends Component {
//   constructor() {
//     super();
//     // Use a ternary operator to make sure that the document object is defined
//     this.el = typeof document !== `undefined` ? document.createElement('div') : null;
//     console.log(this.el);
//   }

//   componentDidMount = () => {
//     portalRoot.appendChild(this.el);
//   };

//   componentWillUnmount = () => {
//     portalRoot.removeChild(this.el);
//   };

//   render() {
//     const { children } = this.props;

//     // Check that this.el is not null before using ReactDOM.createPortal
//     if (this.el) {
//       return ReactDOM.createPortal(children, this.el);
//     } else {
//       return null;
//     }
//   }
// }
