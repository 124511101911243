import React, { Fragment, useState } from 'react';
import MenuPortal from './MenuPortal';
import { useStaticQuery, Link, graphql } from 'gatsby';
import styled, { css } from 'styled-components';
import { animated, useSpring } from 'react-spring';
import { useOnClickOutside } from '../hooks/useOnClickOutside';
import { above, below, minWidth } from '@styles/breakpoints';
import { fontSize } from '@styles/fonts';
import { Hover } from '../components/Hover';
import { useContext } from 'react';
import { LanguageContext } from './Layout';
import { ExternalLinkIcon } from '../components/ExternalLinkIcon';
import { useWindowSize } from '../hooks/useWindowSize';
import { useEffect } from 'react';
import { Block } from '@components/Blocks';

const MenuFontSize = css`

  ${below.med`
    font-size: 2em;
  `}

  ${above.med`
    font-size: ${fontSize('large')};
  `}

  /* ${above.large`
    font-size: ${fontSize('large')};
  `} */

  ${minWidth(2560)`
      font-size: 2em;
  `}
   /* font-size: ${fontSize('menu')}; */
  @media (orientation: landscape) {
    /* font-size: 2em; */
  }

  @media (orientation: portrait) {
  }
`;

export const Menu = ({ menuRef, isOpen, closeMenu }) => {
  const [menu, setMenu] = useState();
  useEffect(() => {
    setMenu(document.getElementById('menu'));
  }, []);

  return (
    <Fragment>
      <MenuPortal containerRef={menu}>
        <MenuInner menuRef={menuRef} isOpen={isOpen} closeMenu={closeMenu} />
      </MenuPortal>
    </Fragment>
  );
};

const MenuInnerWrapper = styled.div`
  position: absolute;
  right: 0;
  z-index: 10000;
  pointer-events: all;
  background: ${({ theme }) => theme.primary};
  color: ${({ theme }) => theme.secondary};
  a {
    color: ${({ theme }) => theme.secondary};
    :hover {
      color: ${({ theme }) => theme.link};
    }
  }

  transform-style: preserve-3d;
  padding: 0 2em 0 1.5em;
  font-weight: 600;
  height: 100%;
  /* max-width: 80vw; */

  overflow-y: scroll;

  display: flex;
  flex-flow: column;

  ${above.med`
    justify-content: flex-start;
  `}

  ${below.med`
    justify-content: center;
  `}

  > div {
    padding: 15vh 0;
    /* ${above.hdReady`
      padding: 15vh 0;
    `}

    ${below.hdReady`
      padding: 15vh 0;
    `} */
  }

  ul {
    margin: 0;
  }

  li {
    list-style: none;
  }
  &.extras {
    font-size: 0.5em;
    li {
      line-height: 1.5;
      font-weight: 500;
    }
  }

  ${MenuFontSize}
`;

const MenuList = styled.ul`
  display: block;
  width: 100%;
  padding: 0;
  list-style-type: none;

  ${above.med`
    font-size: ${({ fontSize = 'inherit' }) => (fontSize === 'small' ? '0.5em' : fontSize)};
  `}
  ${below.med`
    font-size: ${({ fontSize = 'inherit' }) => (fontSize === 'small' ? '0.75em' : fontSize)};
  `}
`;
const AnimatedMenu = animated(MenuInnerWrapper);

const MenuInner = ({ closeMenu, isOpen, menuRef }) => {
  const { menu: { mainItems = [], externalItems = [] } = {} } = useStaticQuery(query);

  useOnClickOutside(menuRef, closeMenu);
  const transition = useSpring({
    transform: !isOpen ? 'translateX(100%)' : 'translateX(0%)',
    minWidth: '50vw',
  });

  const lang = useContext(LanguageContext);

  const { innerHeight } = useWindowSize(10);
  useEffect(() => {
    if (menuRef.current) {
      // menuRef.current.style.height = `${innerHeight}px`;
    }
  }, [innerHeight]);

  return (
    <AnimatedMenu column justify="flex-start" ref={menuRef} style={transition}>
      {/* <HeaderPadding /> */}
      {/* <Spacer /> */}
      <div>
        <Block type="l">
          <MenuList>
            {mainItems.map((item) => (
              <li key={item._key}>{<MenuItem item={item} closeMenu={closeMenu} />}</li>
            ))}
          </MenuList>
        </Block>
        <Block type="l">
          <MenuList>
            {externalItems.map((item) => (
              <li key={item._key}>{<ExternalMenuItem item={item} closeMenu={closeMenu} />}</li>
            ))}
          </MenuList>
        </Block>
        {/* <Spacer /> */}
        <Block type="l">
          <ExtraItems closeMenu={closeMenu} />
        </Block>
      </div>
      {/* <Spacer /> */}
    </AnimatedMenu>
  );
};

const MenuItem = ({ item, closeMenu }) => {
  const lang = useContext(LanguageContext);

  if (item._type === 'menuItem') {
    return (
      <Link to={`/${lang}${item.path}`} onClick={closeMenu}>
        <Hover>{item.title[lang]}</Hover>
      </Link>
    );
  }
  if (item._type === 'menuItemCustomPage') {
    const { page: { slug: { current: slug = '' } = {} } = {} } = item;
    const url = `/${lang}/page/${slug}`;

    return (
      <Link to={url} onClick={closeMenu}>
        <Hover>{item.title[lang]}</Hover>
      </Link>
    );
  }

  return (
    <Link to={`/${lang}${item.path}`} onClick={closeMenu}>
      <Hover>{item.title[lang]}</Hover>
    </Link>
  );
};
const ExternalMenuItem = ({ item, closeMenu }) => {
  const lang = useContext(LanguageContext);

  if (item._type === 'menuItem') {
    return (
      <Link to={`/${lang}${item.path}`} onClick={closeMenu}>
        <Hover>
          {item.title[lang]}
          <ExternalLinkIcon />
        </Hover>
      </Link>
    );
  }
  if (item._type === 'menuItemCustomPage') {
    const { page: { slug: { current: slug = '' } = {} } = {} } = item;
    const url = `/${lang}/page/${slug}`;

    return (
      <Link to={url} onClick={closeMenu}>
        <Hover>
          {item.title[lang]}
          <ExternalLinkIcon />
        </Hover>
      </Link>
    );
  }

  return (
    <a href={item.url} onClick={closeMenu}>
      <Hover>
        {item.title[lang]}
        <ExternalLinkIcon />
      </Hover>
    </a>
  );
};

const ExtraItems = ({ closeMenu }) => {
  const lang = useContext(LanguageContext);
  if (lang === `en`) {
    return (
      <MenuList>
        <li>
          <Link to={`/nb/`} onClick={closeMenu}>
            Gyro på Norsk
          </Link>
        </li>
        <li>
          <Link to={`/en/page/privacy-policy`} onClick={closeMenu}>
            Privacy Policy
          </Link>
        </li>
      </MenuList>
    );
  }

  return (
    <MenuList fontSize="small">
      <li>
        <Link to={`/en/`} onClick={closeMenu}>
          Gyro in English
        </Link>
      </li>
      <li>
        <Link to={`/nb/page/privacy-policy`} onClick={closeMenu}>
          Personvern
        </Link>
      </li>
    </MenuList>
  );
};

const query = graphql`
  query {
    menu: sanityMenu(_id: { regex: "/(drafts.|)menu/" }) {
      mainItems {
        __typename
        ... on SanityMenuItem {
          _key
          _type
          title {
            en
            nb
          }
          path
        }
        ... on SanityExternalMenuItem {
          _key
          _type
          title {
            en
            nb
          }
          url
        }
        ... on SanityMenuItemCustomPage {
          _key
          _type
          title {
            en
            nb
          }
          page {
            _id
            slug {
              current
            }
          }
        }
      }
      externalItems {
        __typename
        ... on SanityMenuItem {
          _key
          _type
          title {
            en
            nb
          }
          path
        }
        ... on SanityExternalMenuItem {
          _key
          _type
          title {
            en
            nb
          }
          url
        }
        ... on SanityMenuItemCustomPage {
          _key
          _type
          title {
            en
            nb
          }
          page {
            _id
            slug {
              current
            }
          }
        }
      }
    }
  }
`;
