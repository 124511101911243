import React, { useEffect } from 'react'
import { ReplaySubject } from 'rxjs'
import uuid from 'uuid/v4';
const PAGETITLE_MAP = new Map();
export const PageTitleSubject = new ReplaySubject()

function refreshSubject() {
    const array = Array.from(PAGETITLE_MAP.values());
    PageTitleSubject.next(array)
}

export function usePageTitle(title) {

    useEffect(() => {
        const key = uuid();
        PAGETITLE_MAP.set(key, title)
        refreshSubject()
        return () => {
            PAGETITLE_MAP.delete(key)
            refreshSubject()
        };
    }, [title])
}