import React, { useRef, useState } from 'react';
import { MenuButtonSize } from './MenuButton';
import styled from 'styled-components';
import { CleanButton } from '../components/CleanButton';
import { animated, useSpring } from 'react-spring';
import { useContext } from 'react';
import { ShowLogoContext } from './Layout';
import { useScrollPosition } from '../hooks/useScrollPosition';
import { useGlobalMouseOverAdvanced } from '../hooks/useMouseOverAdvanced';

const AnimatedButton = animated(CleanButton);

export const Logo = styled(({ className, onClick }) => {

    const showLogo = useContext(ShowLogoContext);
    const scrollY = useScrollPosition();
    const isVisible = showLogo || scrollY > 500;


    const [isHover, setIsHover] = useState(false);

    const ref = useRef();

    useGlobalMouseOverAdvanced(ref, (extra) => setIsHover(extra.isMouseOver));

    const props = useSpring({
        opacity: isVisible ? 1 : 0,
        pointerEvents: isVisible ? 'auto' : 'none'
    })

    // bot right
    const s1 = useSpring({
        transform: isHover ? `translate(5px, 2px)` : `translate(0px, 0px)`
    })

    // bot left
    const s2 = useSpring({
        transform: isHover ? `translate(-5px, 8px)` : `translate(0px, 0px)`
    })

    // mid
    const s3 = useSpring({
        transform: isHover ? `translate(-5px, 0px)` : `translate(0px, 0px)`
    })
    // top
    const s4 = useSpring({
        transform: isHover ? `translate(0px, -5px)` : `translate(0px, 0px)`
    })

   

    return (
        <AnimatedButton onClick={onClick} style={props}>
            <svg
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                x="0px"
                y="0px"
                viewBox="0 0 100 100"
                className={className}
                ref={ref}
                overflow="visible"
            >
                <animated.path style={s1} d="M80.7,43.6V74c-5.9,6.4-16.1,9.5-26.3,9.5l-0.1-12.8c5.3,0,10.5-1,13.6-3.4V56L80.7,43.6z" />
                <animated.path style={s2} d="M54.4,83.4C34.2,83.4,19.3,69,19.3,50h13.3c0,11.7,8.3,20.7,21.7,20.7L54.4,83.4z" />
                <animated.polygon style={s3} points="67.8,56 53.9,56 53.9,43.6 80.7,43.6 	" />
                <animated.path style={s4} d="M19.3,50c0-18.5,14.2-33.4,34-33.4c9.4,0,17.1,2.8,22.9,7.6l-8.9,9.5c-3.5-2.6-8.1-4.4-14-4.4C41,29.3,32.7,38.7,32.7,50"
                />
            </svg>
        </AnimatedButton>
    )
})`
    ${MenuButtonSize}
    fill: ${({ theme }) => theme.primary};
    path, polygon {
        transition: background-color .5s ease,color .5s ease, fill .5s ease, stroke .5s ease2;
    }
`