import React from "react";
import PropTypes from "prop-types";
import cc from "classcat";

const Layout = ({ pos = false, children }) => (
  <div
    className={cc([
      {
        layout: !pos,
        [`layout-${pos}`]: !!pos,
      },
    ])}
  >
    {children}
  </div>
);

Layout.propTypes = {
  pos: PropTypes.oneOf(["center", "left", "reverse", "h", "v", "hv", "top", "right", "bottom"]),
};

export default Layout;
