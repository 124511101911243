import React, { useRef } from 'react';
import { animated, useSpring, config } from 'react-spring';
import { useGlobalMouseOverAdvanced } from '../hooks/useMouseOverAdvanced';
import styled from 'styled-components';
import { useEffect } from 'react';
import { clamp } from '@lib/helpers';

const Wrapper = styled.div`
  display: ${({ display = 'inline-flex' }) => display};
  height: ${({ height = 'auto' }) => height};
  transform-style: preserve-3d;
  transform-origin: center;
  perspective: 600px;
`;

export const InnerW = Wrapper;

const Inner = animated(Wrapper);

export const hoverTransform = (y, x, degree) => `rotate3d(${x}, ${y}, 0, ${degree}deg)`;

export const Hover = styled(
  ({
    className,
    children,
    maxDegree = 25,
    display,
    height,
    disabled = false,
    rotationRef = { current: null },
    configOpts = {},
  }) => {
    const { mass = 1, tension = 100, friction = 26 } = configOpts;
    const ref = useRef();
    const [props, set] = useSpring(() => ({ xys: [0, 0, 1], config: { mass, tension, friction } }));

    useGlobalMouseOverAdvanced(ref, (extra) => {
      if (disabled) {
        set({ xys: [0, 0, 0], config: config.wobbly });
        rotationRef.current = [0, 0, 0];
        return;
      }
      if (extra.isMouseOver) {
        const { x, y, centerX, centerY, width, height } = extra;
        const xx = x - centerX;
        const yy = y - centerY;

        const xxx = xx / centerX;
        const yyy = -(yy / centerY);

        const radius = Math.sqrt(Math.pow(xxx, 2) + Math.pow(yyy, 2));
        const degree = clamp(radius * 25, 0, maxDegree);

        set({ xys: [xxx, yyy, degree] });
        rotationRef.current = [xxx, yyy, degree];
      } else {
        set({ xys: [0, 0, 0] });
        rotationRef.current = [0, 0, 0];
      }
    });

    useEffect(() => {
      if (disabled) {
        set({ xys: [0, 0, 0], config: config.wobbly });
        rotationRef.current = [0, 0, 0];
        return;
      }
    }, [disabled]);

    return (
      <Wrapper height={height} display={display} className={className} ref={ref}>
        <Inner height={height} display={display} style={{ transform: props.xys.interpolate(hoverTransform) }}>
          {children}
        </Inner>
      </Wrapper>
    );
  }
)``;
