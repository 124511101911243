import React from 'react';
import { CleanButton } from '../components/CleanButton';
import styled, { css } from 'styled-components';
import { animated, useSpring } from 'react-spring';
import { above, minWidth } from '../styles/breakpoints';

export const MenuButtonSize = css`
    width: 4em;
    height: 4em;
    ${minWidth(375)`
        width: 3em;
        height: 3em;
    `}
    ${minWidth(400)`
        width: 4em;
        height: 4em;
    `}
    ${above.med`
        width: 2.5em;
        height: 2.5em;
    `}
    pointer-events: auto;
    fill: ${({ theme }) => theme.primary};
    stroke: ${({ theme }) => theme.primary};
`;
export const MenuButton = styled(({ className, onClick, style, isOpen }) => {
  const { y11, y22, x1, x2, ...props } = useSpring({
    y11: isOpen ? 20 : 80,
    y22: isOpen ? 80 : 20,
    x1: isOpen ? 50 : 80,
    x2: isOpen ? 50 : 20,
    opacity: isOpen ? 0 : 1,
  });

  return (
    <CleanButton onClick={onClick} style={style}>
      <svg viewBox="0 0 100 100" className={className}>
        <animated.line x1="20" y1={y11} x2="80" y2="80" strokeWidth="6" />
        <animated.line x1="80" y1="20" x2="20" y2={y22} strokeWidth="6" />
        <animated.line x1={x1} y1="50" x2={x2} y2="50" strokeWidth="6" style={props} />
      </svg>
    </CleanButton>
  );
})`
    ${MenuButtonSize}
    
    stroke: ${({ theme }) => theme.primary};
    fill: ${({ theme }) => theme.primary};
`;
