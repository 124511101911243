import React from 'react';
import styled from 'styled-components';

const InternalFlex = React.forwardRef((props, ref) => {
  const { className, style, children, justify, align, wrap, column, inline, row, overflow, ...rest } = props;

  return (
    <div className={className} style={style} {...rest} ref={ref}>
      {children}
    </div>
  );
});

export const Flex = styled(InternalFlex)`
  display: ${({ inline }) => (inline ? 'inline-flex' : 'flex')};
  flex-direction: ${({ column }) => (column ? 'column' : 'row')};
  flex-wrap: ${({ wrap = 'nowrap' }) => (wrap === true ? 'wrap' : wrap)};
  justify-content: ${({ justify = 'flex-start' }) => justify};
  align-content: ${({ align = 'flex-start' }) => align};
  align-items: ${({ align = 'flex-start' }) => align};
  overflow: ${({ overflow }) => (overflow === true ? 'visible' : overflow ? overflow : 'inherit')};
`;

export const Spacer = styled.div`
  flex-grow: 1;
`;
