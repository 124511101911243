import React, { useRef, useEffect, Fragment, useState, useContext } from 'react';
import styled, { ThemeConsumer, ThemeProvider } from 'styled-components';
import { Flex } from '../components/Flex';
import { Menu } from './Menu';
import { Logo } from './Logo';
import { MenuButton } from './MenuButton';

import { navigate } from 'gatsby';
import { useObservable } from '../hooks/useObservable';
import { PageTitleSubject } from '../hooks/usePageTitle';
import { animated, useSpring } from 'react-spring';
import { useScrollPosition } from '../hooks/useScrollPosition';
import { fontSize } from '../styles/fonts';
import { above, below, minWidth } from '../styles/breakpoints';
import { ReplaySubject } from 'rxjs';
import { HeaderPadding } from './LayoutPadding';
import { LanguageContext } from './Layout';

const HeaderWrapper = styled(Flex)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  ${HeaderPadding};
  z-index: 1001;
  ${above.fullHD`
    font-size: 1.5rem;
  `}
  /* ${minWidth(2560)`
    font-size: 1.5vw;
  `} */
  pointer-events: none;
`;

export const HeaderHeightSubject = new ReplaySubject(1);

export const Header = () => {
  const menuRef = useRef();
  const headerRef = useRef();
  const [isOpen, setIsOpen] = useState(false);

  const lang = useContext(LanguageContext);

  useEffect(() => {
    if (headerRef.current) {
      const { height = 0 } = headerRef.current.getBoundingClientRect();
      HeaderHeightSubject.next(height);
    }
  });
  return (
    <Fragment>
      <HeaderWrapper id="header" justify="space-between" align="center" ref={headerRef}>
        <Logo onClick={() => navigate(`/${lang}/`)} />
        <PageTitle />
        <MenuButton onClick={() => setIsOpen((val) => !val)} isOpen={isOpen} />
      </HeaderWrapper>
      <FixedHeader menuRef={menuRef} isOpen={isOpen} closeMenu={() => setIsOpen(false)} />
      <Menu menuRef={menuRef} isOpen={isOpen} closeMenu={() => setIsOpen(false)} headerRef={headerRef} />
    </Fragment>
  );
};

const FixedHeader = ({ menuRef, onMenuButtonClick, isOpen }) => {
  const fixedHeader = useRef();

  useEffect(() => {
    let running = true;
    let id;

    const logic = () => {
      if (fixedHeader.current && menuRef.current) {
        const { left } = menuRef.current.getBoundingClientRect();
        fixedHeader.current.style.clip = `rect(auto, auto, auto, ${left}px)`;
      } else {
        fixedHeader.current.style.clip = `rect(auto, auto, auto, 9000px)`;
      }
    };

    const loop = () => {
      if (!running) {
        return;
      }
      logic();
      id = requestAnimationFrame(loop);
    };

    id = requestAnimationFrame(loop);

    return () => {
      running = false;
      cancelAnimationFrame(id);
    };
  }, []);

  return (
    <ThemeConsumer>
      {(theme) => (
        <ThemeProvider theme={{ primary: theme.secondary, secondary: theme.primary, link: theme.link }}>
          <HeaderWrapper
            ref={fixedHeader}
            justify="space-between"
            align="center"
            style={{ zIndex: 1002, pointerEvents: 'none' }}
          >
            <Logo onClick={() => navigate('/')} />
            <PageTitle />
            <MenuButton isOpen={isOpen} onClick={onMenuButtonClick} />
          </HeaderWrapper>
        </ThemeProvider>
      )}
    </ThemeConsumer>
  );
};

const Original = animated(styled.h1`
  font-weight: 600;
  color: ${({ theme }) => theme.primary};
  ${above.med`
        font-size: ${fontSize('header')};
    `}
  ${below.med`
        font-size: ${fontSize('med')};
    `}
  
  max-width: 50%;
  text-align: center;
  margin: 0;
  height: 3em;
  display: inline-flex;
  align-items: center;
  span {
    white-space: pre;
  }
  ${above.med`
        max-width: 100%;
        height: auto;
    `}
  ${below.med`
        display: inline-flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    `}
`);

const PageTitle = () => {
  const titles = useObservable(PageTitleSubject, []);
  const [title] = (titles && titles.reverse()) || [];
  /* const query = useStaticQuery(
        graphql`
        query HeaderPageQuery {
            site: sanitySiteSettings(_id: {regex: "/(drafts.|)siteSettings/"}) {
                slogan
            }
        }
        `)
    const slogan = query.site.slogan; */
  const y = useScrollPosition();
  const visible = y < 100;

  const style = useSpring({
    opacity: visible ? 1 : 0,
  });

  const slogan = `<span>We accelerate people,</span><span> cultures and brands</span>`;

  const html = {
    __html: title || slogan,
  };

  return <Original style={style} dangerouslySetInnerHTML={html} />;
};
