import React from 'react';
import styled from 'styled-components';
import { fontSize, Weight } from '@styles/fonts';
import { Link as _Link } from 'gatsby';

// Paragraphs
export const Paragraph = styled.p`
  font-size: ${({ size } = {}) => fontSize(size)};
  font-weight: ${({ weight = 'medium' } = {}) => Weight[weight]};
  text-align: ${({ alignment = 'left' } = {}) => alignment};
  &:last-child:first-child {
    margin: 0;
  }
`;

export const Ingress = ({ alignment, weight = 'semi', size = 'ingress', children }) => (
  <Paragraph alignment={alignment} size={size} weight={weight}>
    {children}
  </Paragraph>
);

// Links
const StyledLink = styled(Paragraph)`
  text-decoration: underline;
`;

export const Link = ({ children, ...props }) => (
  <StyledLink {...props} as={_Link}>
    {children}
  </StyledLink>
);

// Heading
const StyledHeading = styled.div`
  font-size: ${({ size = 'title' } = {}) => fontSize(size)};
  margin-top: ${({ margin }) => margin};
  margin-bottom: ${({ margin }) => margin};
  &:last-child:first-child {
    margin: 0;
  }
`;

export const Heading = ({ h = 2, children, ...props }) => (
  <StyledHeading {...props} as={`h${h}`}>
    {children}
  </StyledHeading>
);
