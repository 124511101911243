export const THEMES = [
	{
		primary: '#ae8150',
		secondary: '#f2f2f2',
		link: '#725b43',
	},
	{
		primary: '#004742',
		secondary: '#ffd8bc',
		link: '#ffffff',
	},
	{
		primary: '#02445b',
		secondary: '#b4efc3',
		link: '#ffffff',
	},
	{
		primary: '#323c74',
		secondary: '#f0a7bb',
		link: '#ffffff',
	},
	{
		primary: '#fc6349',
		secondary: '#004742',
		link: '#ffffff',
	},
	{
		primary: '#fc6349',
		secondary: '#3149aa',
		link: '#ffffff',
	},
	{
		primary: '#004742',
		secondary: '#f2d24c',
		link: '#ffffff',
	},
	{
		primary: '#f2f2f2',
		secondary: '#541c4f',
		link: '#ffffff',
	},
	{
		primary: '#ffd8bc',
		secondary: '#641431',
		link: '#ffffff',
	},
	{
		primary: '#f9d1cf',
		secondary: '#02445b',
		link: '#ffffff'
	},
]