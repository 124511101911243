import React from 'react';
import styled from 'styled-components';
import { Block, Col } from '@components/Blocks';
import { below, above } from '@styles/breakpoints';
import { Link, Paragraph } from '@components/Text/Text';

const GyroSmile = styled(({ className }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 181 205.25" className={className}>
    <g>
      <polyline points="104.39 84.8 104.39 130.21 80.25 130.21 80.25 59.32" />
      <path d="M165,33.35c-13.79-13.79-34.31-12.69-48.25,1.25L99.23,17.06C122-5.66,157.82-5.82,181,17.37" />
      <path d="M65.79,33.35C52,19.56,31.49,20.66,17.54,34.6L0,17.06c22.71-22.72,58.59-22.88,81.77.31" />
      <path d="M152.88,179.09c-35.76,35.67-84.79,33.57-116.71,1.57L54.68,162.2c22.06,22.11,54.64,25,81-1.31" />
      <circle cx="141.62" cy="55.3" r="13.97" />
    </g>
  </svg>
))`
  height: 6em;
`;

const FooterBlock = styled.div`
  display: flex;
  padding: 20px 0;

  ${below.med`
    justify-content: center;
  `}

  ${above.med`
    justify-content: ${({ align, alignSmall = align }) => alignSmall};
  `}

  ${above.large`
    justify-content: ${({ align, alignLarge = align }) => alignLarge};
  `}
`;

const LinkButton = styled(Link)`
  padding: 10px 15px;
  background-color: ${({ theme }) => theme.primary};
  color: ${({ theme }) => theme.secondary};
`;

const StyledFooter = styled.footer`
  padding: 0 3em 3em;

  ${below.fullHD`
    margin-top: 1em;
  `}

  ${above.fullHD`
    margin-top: 3em;
  `}
`;

const Footer = () => (
  <StyledFooter>
    <Col type={3} align="h">
      <FooterBlock align={'flex-start'}>
        <Paragraph>
          Pontoppidans gate 7, 0462 Oslo
          <br />
          Innkjøring fra Thurmanns gate 10
          <br />
          <br />
          hei@gyro.no
          <br />
          +47 22 92 62 00
          <br />
        </Paragraph>
      </FooterBlock>
      <FooterBlock alignLarge="center" alignSmall="flex-end">
        {/* <Block>
            <Paragraph>Meld deg på vårt nyhetsbrev</Paragraph>
          </Block> */}
        <Block type="t">
          <LinkButton as="a" href="http://eepurl.com/gFt005">
            Meld deg på vårt nyhetsbrev
          </LinkButton>
        </Block>
      </FooterBlock>
      <FooterBlock alignLarge="flex-end" alignSmall="center">
        <div>
          <GyroSmile />
        </div>
      </FooterBlock>
    </Col>
  </StyledFooter>
);

export default Footer;
// <Flex className={className} justify="space-between">
//   <StyledFooter size="footer">
//     Pontoppidans gate 7, 0462 Oslo
//     <br />
//     Innkjøring fra Thurmanns gate 10
//     <br />
//     <br />
//     hei@gyro.no
//     <br />
//     +47 22 92 62 00
//     <br />
//   </StyledFooter>
//   <div>http://eepurl.com/gFt005</div>
//   <div>
//     <GyroSmile />
//   </div>
// </Flex>
