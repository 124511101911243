import React from 'react';
import PropTypes from 'prop-types';
import cc from 'classcat';

const Block = ({
  type = 'm',
  backgroundColor = null,
  hidden = false,
  borderBottomColor = null,
  backgroundImage = null,
  inherit = false,
  blockRef: ref,
  style,
  children,
}) => (
  <div
    ref={ref}
    className={cc([`block-${type}`, { 'block-inherit': inherit, 'block-visibility-hidden': hidden }])}
    style={{
      ...style,
      backgroundColor: backgroundColor && `#${backgroundColor}`,
      borderBottom: borderBottomColor && `1px solid #${borderBottomColor}`,
      backgroundImage: backgroundImage && `url(${backgroundImage})`,
    }}
  >
    {children}
  </div>
);

Block.propTypes = {
  type: PropTypes.oneOf([
    '0',
    'xt',
    't',
    's',
    'm',
    'l',
    'h',
    '0-static',
    't-static',
    's-static',
    'm-static',
    'l-static',
    'h-static',
    '0-anim',
    't-anim',
    's-anim',
    'm-anim',
    'l-anim',
    'h-anim',
  ]),
  backgroundColor: PropTypes.string,
  borderBottomColor: PropTypes.string,
  backgroundImage: PropTypes.string,
  inherit: PropTypes.bool,
};

export default Block;
