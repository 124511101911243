import React from 'react';
import styled from 'styled-components';

export const ExternalLinkIcon = styled(({className}) => (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26 24">
  <path d="M26.45,9.51l-9-9.1L15,2.88l4.52,4.55H17.32a16.94,16.94,0,0,0-16.87,17H4.56A12.81,12.81,0,0,1,17.32,11.58h2.21L15,16.17l2.45,2.46,9-9.1h0Z" transform="translate(-0.45 -0.42)" fillRule="evenodd"></path>
</svg>
))`
    margin-left: 0.25em;
    stroke: currentColor;
    fill: currentColor;
    height: 1em;
    width: 0.75em;
`