import { css } from 'styled-components';

export const Weight = {
  //thin: 100,
  //extraLight: 200,
  light: 300,
  regular: 400,
  medium: 500,
  semi: 600,
  bold: 700,
  //extraBold: 800,
  //black: 900,
};

export const fontWeightDeprecated = (weight) => css`
  font-weight: ${Weight[weight]};
`;
export const fontWeight = (weight) => Weight[weight];

export const fontSize = (size) => {
  switch (size) {
    case 'title':
      return '2.5em';
    case 'card':
      return '1.5em';
    case 'card-title':
      return '1.25em';
    case 'header':
      return '1.5em';
    case 'menu':
      return '3em';
    case 'ingress':
      return '2em';
    case 'label':
      return '1.5em';
    case 'employee-name':
      return '1.5em';
    case 'employee-title':
      return '1em';
    case 'front-heading':
      return '1.25em';
    case 'footer':
      return '1em';

    case 'xLarge':
      return '4em';
    case 'large':
      return '2.5em';
    case 'med':
      return '1.3em';
    case 'small':
      return '1em';
    default:
      return '1em';
  }
};

export const textAlign = (props) => {
  if (!props) {
    return 'left';
  }
  if (props.left) {
    return 'left';
  }
  if (props.center) {
    return 'center';
  }
  if (props.right) {
    return 'right';
  }
  return 'left';
};

export const fontWeightSwitch = (props) => {
  if (!props) {
    return fontWeightDeprecated('normal');
  }

  let hit = 'normal';

  Object.keys(Weight).some((key) => {
    if (props[key] === true) {
      hit = key;
      return true;
    }
    return false;
  });

  return fontWeightDeprecated(hit);
};
