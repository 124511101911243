import React from 'react';
import PropTypes from 'prop-types';
import cc from 'classcat';

/*

  <Col>
    <Component />
    <Component />
  </Col>

  Will automatically wrap components in div.
*/

const Col = ({ type = '2', align = false, children }) => (
  <div className={cc([`col-${type}`, { [`col-${align}`]: align }])}>
    {children.map ? (
      children.map((child, index) => <div key={child.key ? child.key : index}>{child}</div>)
    ) : (
      <div>{children}</div>
    )}
  </div>
);

Col.propTypes = {
  type: PropTypes.oneOf(['1-2', 2, '2', '2-1', '2-center', 3, '3', '3-x', 4, '4', '4-x', 5, '5', 'x', '1-x']),
  align: PropTypes.oneOf(['center', 'h', 'v', 'hv', 'end']),
};

export default Col;

// {children.map
// 	? children.map((child, index) => <div key={child.key ? child.key : index}>{child}</div>)
// 	: children.props.children.map
// 	? children.props.children.map(child => <div key={child.key}>{child}</div>)
// 	: children}
