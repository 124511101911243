import { useEffect, useState } from "react";


/**
 * Returns an object with inner and outer height and width.
 * @param debounceTime Defaults to 250
 */
export function useWindowSize(debounceTime = 250) {
    const { innerWidth = 0, innerHeight = 0, outerWidth = 0, outerHeight = 0 } = typeof window !== `undefined` ? window : {};
    const [state, setState] = useState({
        innerWidth,
        innerHeight,
        outerWidth,
        outerHeight,
    });

    useEffect(() => {

        let debounceTimeout = null;

        const listener = () => {
            clearTimeout(debounceTimeout);
            debounceTimeout = setTimeout(() => {
                const { innerWidth, innerHeight, outerWidth, outerHeight } = window;
                setState({
                    innerWidth,
                    innerHeight,
                    outerWidth,
                    outerHeight,
                })
            }, debounceTime)
        }

        window.addEventListener('resize', listener);

        return function cleanup() {
            window.removeEventListener('resize', listener);
        }
    }, [debounceTime]);


    return state;
}