import React from 'react';
import cc from 'classcat';
import PropTypes from 'prop-types';

const Width = ({ type = 'm', extra = false, children }) => (
  <div className={cc([{ [`width-${type}`]: !extra, [`width-${type}-${extra}`]: !!extra }])}>{children}</div>
);

Width.propTypes = {
  type: PropTypes.oneOf(['t', 's', 'm', 'l', 'h']),
  extra: PropTypes.oneOf(['nested', 'min', 'max']),
};

export default Width;
