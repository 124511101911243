import { useEffect, useState, useRef } from "react";
import { Observable } from "rxjs";

/**
 * 
 * @param {Observable<T>} obs 
 * @param {any} initialState 
 * @param {(err: any) => void} errorHandler 
 * @param {() => void} completeHandler 
 */
export function useObservable(obs, initialState, errorHandler, completeHandler) {
    const [state, setState] = useState(initialState)
    const errRef = useRef(errorHandler);
    const completeRef = useRef(completeHandler);

    useEffect(() => {
        errRef.current = errorHandler;
    }, [errorHandler])

    useEffect(() => {
        completeRef.current = completeHandler;
    }, [completeHandler])

    useEffect(() => {
        const error = (err) => errRef.current ? errRef.current(err) : undefined;
        const complete = () => completeRef.current ? completeRef.current() : undefined;
        const subscription = obs.subscribe(setState, error, complete)

        return () => {
            if (subscription) {
                subscription.unsubscribe();
            }
        }
    }, [obs])

    return state;
}