/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
const React = require('react');
const Layout = require('./src/layout/Layout').Layout;
const ThemeProvider = require('styled-components').ThemeProvider;
const GlobalStyle = require('./src/styles/GlobalStyle').GlobalStyle;
const { defineCustomElements } = require('./src/stencil/dist/esm/loader');
const { applyPolyfills } = require('./src/stencil/dist/esm/polyfills');

require('./src/styles/global.css');
require('./src/styles/scss/global.scss');

exports.wrapPageElement = ({ element, props }) => {
  // props provide same data to Layout as Page element will get
  // including location, data, etc - you don't need to pass it

  const cssVarTheme = {
    primary: 'var(--theme-primary, #ae8150)',
    secondary: 'var(--theme-secondary, #f2f2f2)',
    link: 'var(--theme-link, #725b43)',
  };

  return (
    <ThemeProvider theme={cssVarTheme}>
      <React.Fragment>
        <GlobalStyle />
        <Layout {...props}>{element}</Layout>
      </React.Fragment>
    </ThemeProvider>
  );
};

exports.onClientEntry = () => {
  applyPolyfills().then(() => {
    process.browser && defineCustomElements(window);
  });
};
